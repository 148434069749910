/* Riot v9.4.4, @license MIT */
import { SLOTS_KEY, ROOT_KEY, PROPS_KEY, STATE_KEY } from '../dependencies/@riotjs/util/constants.js';
import { defineProperties, defineDefaults } from '../dependencies/@riotjs/util/objects.js';
import { COMPONENT_DOM_SELECTORS } from './component-dom-selectors.js';
import { COMPONENT_LIFECYCLE_METHODS } from './component-lifecycle-methods.js';
import cssManager from './css-manager.js';
import curry from '../dependencies/curri/index.js';
import { manageComponentLifecycle } from './manage-component-lifecycle.js';

/**
 * Component definition function
 * @param   {Object} implementation - the component implementation will be generated via compiler
 * @param   {Object} component - the component initial properties
 * @returns {Object} a new component implementation object
 */
function instantiateComponent({ css, template, componentAPI, name }) {
  // add the component css into the DOM
  if (css && name) cssManager.add(name, css);

  return curry(manageComponentLifecycle)(
    defineProperties(
      // set the component defaults without overriding the original component API
      defineDefaults(componentAPI, {
        ...COMPONENT_LIFECYCLE_METHODS,
        [PROPS_KEY]: {},
        [STATE_KEY]: {},
      }),
      {
        // defined during the component creation
        [SLOTS_KEY]: null,
        [ROOT_KEY]: null,
        // these properties should not be overriden
        ...COMPONENT_DOM_SELECTORS,
        name,
        css,
        template,
      },
    ),
  )
}

export { instantiateComponent };
