/* Riot v9.4.4, @license MIT */
import { IS_DIRECTIVE } from '../dependencies/@riotjs/util/constants.js';
import { getName } from '../utils/dom.js';
import { set } from '../dependencies/bianco.attr/index.next.js';

/**
 * Add eventually the "is" attribute to link this DOM node to its css
 * @param {HTMLElement} element - target root node
 * @param {string} name - name of the component mounted
 * @returns {undefined} it's a void function
 */

function addCssHook(element, name) {
  if (getName(element) !== name) {
    set(element, IS_DIRECTIVE, name);
  }
}

export { addCssHook };
