/* Riot v9.4.4, @license MIT */
import { MOUNT_METHOD_KEY, UPDATE_METHOD_KEY, UNMOUNT_METHOD_KEY } from '../dependencies/@riotjs/util/constants.js';
import { noop } from '../dependencies/@riotjs/util/functions.js';

const PURE_COMPONENT_API = Object.freeze({
  [MOUNT_METHOD_KEY]: noop,
  [UPDATE_METHOD_KEY]: noop,
  [UNMOUNT_METHOD_KEY]: noop,
});

export { PURE_COMPONENT_API };
