/* Riot v9.4.4, @license MIT */
import { SHOULD_UPDATE_KEY, ON_BEFORE_MOUNT_KEY, ON_MOUNTED_KEY, ON_BEFORE_UPDATE_KEY, ON_UPDATED_KEY, ON_BEFORE_UNMOUNT_KEY, ON_UNMOUNTED_KEY } from '../dependencies/@riotjs/util/constants.js';
import { noop } from '../dependencies/@riotjs/util/functions.js';

const COMPONENT_LIFECYCLE_METHODS = Object.freeze({
  [SHOULD_UPDATE_KEY]: noop,
  [ON_BEFORE_MOUNT_KEY]: noop,
  [ON_MOUNTED_KEY]: noop,
  [ON_BEFORE_UPDATE_KEY]: noop,
  [ON_UPDATED_KEY]: noop,
  [ON_BEFORE_UNMOUNT_KEY]: noop,
  [ON_UNMOUNTED_KEY]: noop,
});

export { COMPONENT_LIFECYCLE_METHODS };
