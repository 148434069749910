<plans>
<article>
  <h1>תכניות, מסלולים ומחירים</h1>
  <section>
    <div class="course" if={state.show.includes('singles')}>
      <h2> יחידים (כולל השבחות מקצועיות)</h2>
      <div class="offer-content"> מפגשים של כשעה כל אחד</div>
      <section class="offer-pricing">
        <div>
          <li>מפגשים בעלי אלמנט טיפולי מרכזי</li>
          <li>התשלום על בסיס נדיבות
            <a href="/lib/dana" target="_blank"> (דהאנה)</a>
          </li>

        </div>
      </section>
    </div>
    <div class="course" if={state.show.includes('family')}>
      <h2> זוגות ומשפחות</h2>
      <div class="offer-content"> מפגשים של כשעה וחצי כל אחד</div>
      <section class="offer-pricing">
        <div>
          <li>התשלום על בסיס נדיבות
            <a href="/lib/dana" target="_blank"> (דהאנה)</a>
          </li>
        </div>
      </section>
    </div>
    <div class="course" if={state.show.includes('youth')}}>
      <h2>נוער</h2>
      <div class="offer-content"> מפגשים של כשעה כל אחד</div>
      <section class="offer-pricing">
        <div>
          <li>התשלום על בסיס נדיבות
            <a href="/lib/dana" target="_blank"> (דהאנה)</a>
          </li>
        </div>
      </section>
    </div>
    <div class="course" if={state.show.includes('consulting')}}>
      <h2>יעוץ</h2>
      <div class="offer-content"> מפגשים של עד שעתיים כל אחד</div>
      <section class="offer-pricing">
        <div>
          <li>מתודות ניהול מבוססות בהירות</li>
          <li>מנטורינג למנהלים וראשי-צוותים</li>
          <li>מחיר מפגש בודד: 500&#8362;</li>
        </div>
      </section>
    </div>
    <div class="course" if={state.show.includes('organizations')}>
      <h2>סדנאות לארגונים וקבוצות</h2>
      <div class="offer-content">4-8 מפגשים של 3-4 שעות</div>
      <section class="offer-pricing">
        <div>
          <li>החל מ&#8362;11,000</li>
          <li>צרו קשר לתכנון והצעה</li>
          <li>אפשרויות מיוחדות להייטק</li>
          <li>אופציה לליווי מתמשך</li>
        </div>
      </section>
    </div>
    <div class="course" if={state.show.includes('groups')}>
      <h2>סדנא חד-פעמית</h2>
      <div class="offer-content"> שעתיים עד שלוש</div>
      <section class="offer-pricing">
        <div>
          <li>החל מאפס...</li>
          <li>צרו קשר לתכנון והצעה</li>
          <li>כולל יוגה, מדיטציה, סדנאת עיסוי/הילינג</li>
          <li>במקום של הלקוח או בתוספת - מקום אחר</li>
        </div>
      </section>
    </div>
    <div class="course" if={state.show.includes('groups')}>
      <h2>מפגשים קבוצתיים</h2>
      <div class="offer-content"> שעה עד שלוש</div>
      <section class="offer-pricing">
        <div>
          <li>התשלום על בסיס נדיבות
            <a href="/lib/dana" target="_blank"> (דהאנה)</a>
          </li>
          <li>סוגים שונים של מפגשים קבוצתיים</li>
          <li>מעגלי תרגול, לימוד, שיחה וריפוי</li>
          <li>הפרדה בין מתרגלים מנוסים לחדשים</li>
          <li>דהרמה/לו-ג'ונג, העמקה וייעוץ</li>
          <b><a href="/events">ראו בעמוד האירועים</a></b>
        </div>
      </section>
    </div>
    <div class="course" if={state.show.includes('groups')}>
      <h2>לימוד דהרמה פרופר</h2>
      <div class="offer-content">אורך מפגש כשעה</div>
      <section class="offer-pricing">
        <div>
          <li>התשלום על בסיס נדיבות
            <a href="/lib/dana" target="_blank"> (דהאנה)</a>
          </li>
          <li>על המתרגל להיות בעל ניסיון משמעותי קודם</li>
          <li>על המתרגל להיות חרוץ ומסור לדרך</li>
        </div>
      </section>
    </div>

  </section>
  <a if={state.more} class="to-all" href="/plans"><b>לכל התכניות</b></a><br>

</article>
<style>
  :host h2 {
    text-align: center;
  }

  :host section > div {
    margin: 5px;
  }

  .to-all {
    display: block;
    text-align: end;
    text-shadow: 0 0 6px white;
  }
</style>
<script>
  const plans = ['youth', 'family', 'singles', 'consulting', 'organizations', 'groups']

  export default {
    onBeforeMount() {
      const show = this.props.filter && [this.props.filter] || plans
      this.state = {
        show,
        more: show.length < plans.length
      }
    },
    onMounted() {
      const ads = this.$$('.course')
      for (let a of ads) {
        a.style.transform = `rotate(${Math.round(Math.random() * 6 - 3)}deg)`
      }
    }
  }

</script>

</plans>